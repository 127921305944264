import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

interface SocialIcon {
    title: string;
    redirect: string;
    icon: {
        data: {
            attributes: {
                url: string;
            };
        };
    };
}

interface SocialMediaProps {
    socialMediaTitle: string
    isActive: boolean
    socialMedia: SocialIcon[];
}

const SocialMedia: React.FC<SocialMediaProps> = ({ socialMedia, socialMediaTitle, isActive }) => {
    return (
        <Fragment>
            { isActive && (
                <div className={"socialMedia"}>
                    {socialMediaTitle}
                    <span className={"companySocialProfile"}>
                        {socialMedia.map((icon) => (                            
                            <Link to={icon.redirect} key={icon.title}>
                                <img src={icon.icon.data.attributes.url} alt={icon.title} />
                            </Link>
                        ))}
                    </span>
                </div>
    
            )}
        </Fragment>
    );
};

export default SocialMedia;
