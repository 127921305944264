import React from "react";
import DropList from "./DropLayout/DropList";
import { DropLayoutProps,  } from "./GlobalTypes"; // Assuming this is where DropMenuList is defined



const DropLayout: React.FC<DropLayoutProps> = ({ toggleValue, dropMenuItems, dropMenuCard, dropDownTitle, dropMenuSecondaryItems }) => {
    // console.log(dropMenuCard)
    return (
        <React.Fragment>
            <DropList isOpen={toggleValue} items={dropMenuItems} card={dropMenuCard} dropDownTitle={dropDownTitle} secondaryListItems={dropMenuSecondaryItems}/>
        </React.Fragment>
    );
}

export default DropLayout;