import { Spin } from "antd"
import React, { FC, MutableRefObject } from "react"
import "./style.css"

const Loader: FC<{ loading?: boolean; refLoading?: MutableRefObject<null>; spinning?: boolean }> = ({
  loading,
  spinning,
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <span className={"loader"}></span>
      </div>
    </React.Fragment>
  )
}

export { Loader }
