import React, { useEffect, useState } from "react";
import { XPContainer } from "./HeaderStyle";
import { Link } from "react-router-dom";
import HeaderLogo from "./Logo";
import { SvgIcon } from "../ui/SvgIcon"
import DropLayout from "./DropDownLayout";
import { LinksMain, MainHeaderProps } from "./GlobalTypes";

const MainHeader: React.FC<MainHeaderProps> = ({ mainHeader, logo, menuIcons, menuCtas }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
    const [mainMenuList, setMainMenuList] = useState<LinksMain[]>([]);
    const [isFixed, setIsFixed] = useState(false)

    const toggleValueEnter = (id: string) => {
        setIsOpen(true);
        setActiveDropdownId(id);
    };

    const toggleValueLeave = () => {
        setIsOpen(false);
        setActiveDropdownId(null);
    };

    useEffect(() => {
        if (mainHeader && mainHeader.isActive) {
            setMainMenuList(mainHeader.LinksMain || []);
        }
    }, [mainHeader]);

    useEffect(() => {
        const handlePosition = () => {
            if (window.scrollY > 0) {
                setIsFixed(true)
            } else {
                setIsFixed(false)
            }
        }

        window.addEventListener("scroll", handlePosition);
        return () => window.removeEventListener('scroll', handlePosition);
    }, [])

    useEffect(() => {
        if (isOpen) {
            document.querySelector('.wrapperMainContent')?.classList.add('blur-content')
        } else {
            document.querySelector('.wrapperMainContent')?.classList.remove('blur-content')
        }

        return () => {
            document.querySelector('.wrapperMainContent')?.classList.remove('blur-content')

        };
    }, [isOpen]);

    const handleLinkClick = () => {
        setIsOpen(false);
        setActiveDropdownId(null);
    };
   

    const isActiveMenu = (link: LinksMain) => {
        if (!link.Redirect || link.Redirect.trim() === '') return false;
    
        if (location.pathname === link.Redirect) {
            return true;
        }
    
        if (location.pathname.startsWith(`${link.Redirect}/`) && link.Redirect !== '/') {
            return true;
        }
    
        if (location.pathname === '/' && (link.Redirect === '/' || link.Redirect === '/home')) {
            return true;
        }
    
        // Updated logic for subitem matching to ensure it doesn't match the root '/'
        if (link.drop_menu_lists?.data?.some(subLink => subLink.attributes?.Redirect && 
            location.pathname === subLink.attributes.Redirect && subLink.attributes.Redirect !== '/')) {
            return true;
        }
    
        if (link.drop_menu_list_secondaries?.data?.some(subLink => subLink.attributes?.Redirect && 
            location.pathname === subLink.attributes.Redirect && subLink.attributes.Redirect !== '/')) {
            return true;
        }
    
        return false;
    };

    return (
        <section className={`main-header-section ${isFixed ? "fixed-header" : ""}`}>
            <XPContainer className={"container"}>
                <div className={"main-header-container"}>
                    <div className={"main-logo"}>
                        <HeaderLogo logo={logo} />
                    </div>
                    <div className={"main-left-container"}>
                        <ul className={"nav-list"}>
                            {mainMenuList.filter(link => link?.isActive).map(link => (
                                <li
                                    key={link.id}
                                    className={`nav-items ${isActiveMenu(link) ? "active" : ""}`}
                                    onMouseEnter={() => link?.isDropDown && toggleValueEnter(link?.id)}
                                    onMouseLeave={toggleValueLeave}
                                    onClick={handleLinkClick}
                                >
                                    <Link className={"main-nav-link"} to={link?.Redirect} onClick={handleLinkClick}>
                                        {link?.Icon?.data?.attributes?.url && (
                                            <img
                                                className={"main-nav-icon"}
                                                src={link?.Icon?.data?.attributes?.url}
                                                alt={link?.Title}
                                            />
                                        )}
                                        <span className={"main-nav-items"}>
                                            <span className={"main-nav-items-text"}>{link?.Title}</span>
                                            {link?.isDropDown && (
                                                <span className={"dropIcon"}>
                                                    <SvgIcon type={"Chevron"} />
                                                </span>
                                            )}
                                        </span>
                                    </Link>
                                    {link?.isDropDown && isOpen && activeDropdownId === link?.id && (
                                        <div
                                            onMouseEnter={() => setIsOpen(true)}
                                            onMouseLeave={toggleValueLeave}
                                        >
                                            <DropLayout
                                                toggleValue={isOpen}
                                                dropMenuItems={link.drop_menu_lists.data}
                                                dropMenuCard={link.drop_cards}
                                                dropDownTitle={link?.Title}
                                                dropMenuSecondaryItems={link?.drop_menu_list_secondaries?.data}
                                            />
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>


                        {(menuIcons?.isActive || menuCtas?.isActive) && (
                            <div className={"main-header-actions"}>
                                {menuIcons?.isActive && menuIcons?.menuIcon.filter(link => link.isActive).map(link => (
                                    <Link key={link.id} to={link.Redirect} className={"cursor-pointer"}>
                                        <img
                                            src={link?.Icon?.data?.attributes?.url}
                                            alt={"Menu Icon"}
                                        />
                                    </Link>
                                ))}
                                {menuCtas?.isActive && menuCtas.menuCTA.filter(link => link.isActive).map(link => (
                                    link.Title ? (
                                        <button
                                            key={link.id}
                                        >
                                            <img src={link.CTAImage.data.attributes.url} alt={link.Title} />
                                            <Link to={link.Redirect || ""}>{link.Title}</Link>
                                        </button>
                                    ) : (
                                        <button
                                            key={link.id}
                                        >
                                            <Link to={link.Redirect || ""}><img src={link.CTAImage.data.attributes.url} alt={link.Title} /></Link>
                                        </button>
                                    )

                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </XPContainer>
        </section>
    );
};

export default MainHeader;
