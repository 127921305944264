import React, { useEffect, useState } from "react";
import TopHeader from "./TopHeader";
import MainHeader from "./MainHeader";
import HamburgerMenu from "./HamburgerMenu";
import { useChannelpartnerQuery, useGlobalHeaderQuery } from "src/graphql";
import { useMediaQuery } from 'react-responsive'
import "./global.css";
import { Loader } from "../ui/Loader";

const GlobalHeader = () => {
    const {data:globalHeaderData, loading} = useGlobalHeaderQuery();
    const [navbarComponents, setNavbarComponents] = useState<any>({
        alertBanner: null,
        topMenu: null,
        logo: null,
        mainMenu: null,
        menuIcon: null,
        menuCta: null
    });

    useEffect(() => {
        const globalNavbar = globalHeaderData?.globalHeader?.data?.attributes?.Navbar || [];
        const extractedComponents = extractNavbarComponents(globalNavbar);
        setNavbarComponents(extractedComponents);
    }, [globalHeaderData]);
    
    const extractNavbarComponents = (globalNavbar: any[]) => {
        const alertBanner = globalNavbar.find(item => item.__typename === "ComponentUiAlertBanner");
        const topMenu = globalNavbar.find(item => item.__typename === "ComponentUiTopMenu");
        const logo = globalNavbar.find(item => item.__typename === "ComponentUiLogo");
        const mainMenu = globalNavbar.find(item => item.__typename === "ComponentUiMainMenu");
        const menuIcon = globalNavbar.find(item => item.__typename === "ComponentUiMenuIcon");
        const menuCta = globalNavbar.find(item => item.__typename === "ComponentUiMenuCta");
        return { alertBanner, topMenu, logo, mainMenu, menuIcon, menuCta };
    };

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1025px)' });

    if(loading){
        return <Loader />
    }
    return (
        <React.Fragment>
            <div className={"navbar-main-wrapper"}>
                <TopHeader topheader={navbarComponents.topMenu} />
                {
                    isDesktopOrLaptop ? (
                        <MainHeader
                            mainHeader={navbarComponents.mainMenu}
                            logo={navbarComponents.logo}
                            menuIcons={navbarComponents.menuIcon}
                            menuCtas={navbarComponents.menuCta}
                        />

                    ) : (

                        <HamburgerMenu
                            mainMenuList={navbarComponents.mainMenu?.LinksMain || []}
                            logo={navbarComponents.logo}
                            menuIcons={navbarComponents.menuIcon}
                            menuCtas={navbarComponents.menuCta}
                        />
                    )
                }

            </div>
        </React.Fragment>
    );
};

export default GlobalHeader;
