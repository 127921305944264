import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { requestSuccessMessage } from "../../constants";
import { useGeneralContext } from "../../context";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddToCalendar from "../AddToCalendar";
import ShareButton from "../ShareButton";
import XcelButton from "../Button";

interface EventAction {
  Date: string | number | Date;
  id: number;
  Title: string;
  Redirect: string;
  Target: string;
}

interface EventInput {
  id: number;
  InputType: string;
  Placeholder: string;
  Title: string;
  Name: string;
  Value: string;
}

interface EventHeroProps {
  eventData: {
    EventTitle: string;
    EventSubtitle: string;
    CoverBanner: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    EventDay: string;
    EventDate: string;
    EventStartTime: string;
    EventEndTime: string;
    EventVenue: string;
    EventAction: EventAction[];
    FormTitle: string;
    FormSubtitle: { id: number; Subtitle: string }[];
    FormInput: EventInput[];
    FormTerms: string;
    TimeZone: string | null;
  };
  formatDate: (dateString: string) => string;
  formatTime: (timeString: string) => string;
  handleEventAction: (action: EventAction) => void;
}

const EventHero: React.FC<EventHeroProps> = ({
  eventData,
  formatDate,
  formatTime,
  handleEventAction,
}) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const location = useLocation();
  const { messageApi } = useGeneralContext();

  useEffect(() => {
    if (eventData) {
      const eventDate = new Date(`${eventData.EventDate}T${eventData.EventStartTime}`);
      const now = new Date();
      const difference = eventDate.getTime() - now.getTime();

      const timer = setInterval(() => {
        const updatedNow = new Date();
        const updatedDifference = eventDate.getTime() - updatedNow.getTime();
        setTimeRemaining(updatedDifference);
      }, 1000);
      setTimeRemaining(difference);

      return () => clearInterval(timer);
    } else {
      return undefined;
    }
  }, [eventData]);

  const formatDays = (milliseconds: number | null) => {
    if (milliseconds === null || milliseconds < 0) {
      return "Event has started";
    }

    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    return days;
  };

  const formatHours = (milliseconds: number | null) => {
    if (milliseconds === null || milliseconds < 0) {
      return "00";
    }

    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    return hours < 10 ? `0${hours}` : hours.toString();
  };

  const formatMinutes = (milliseconds: number | null) => {
    if (milliseconds === null || milliseconds < 0) {
      return "00";
    }

    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    return minutes < 10 ? `0${minutes}` : minutes.toString();
  };

  const generateBreadcrumb = () => {
    const paths = location.pathname.split("/").filter((path) => path);
    return paths.map((path, index) => (
      <Fragment key={index}>
        <Link to={`/${paths.slice(0, index + 1).join("/")}`}>{path}</Link>
        {index !== paths.length - 1 && " / "}
      </Fragment>
    ));
  };

  const validationSchema = Yup.object(
    eventData.FormInput.reduce((schema, input) => {
      if (input.InputType !== "submit") {
        schema[input.Name] = Yup.string().required(`${input.Title} is required`);
      }
      return schema;
    }, {} as Record<string, Yup.AnySchema>)
  );

  const handleSubmit = async (values: Record<string, string>, { resetForm }: any) => {
    try {
      const adjustedFormData = {
        CompanyName: values.Company,
        JobTitle: values["Job title"],
        Name: values.Name,
        PhoneNumber: values["Phone number"],
        WorkEmail: values["Work email address"],
      };
      const isLocal = window.location.hostname === "localhost";
      const isDev = window.location.hostname.includes("staging.");
      const isUat = window.location.hostname.includes("uat.");

      const baseURL = isLocal
        ? "http://localhost:1337/api/"
        : isDev
          ? "https://staging.xcelpros.com/api/"
          : isUat
            ? "https://uat.xcelpros.com/api/"
            : "https://www.xcelpros.com/api/";

      const response = await axios.post(`${baseURL}event-forms`, { data: adjustedFormData });
      // console.log("Form submitted successfully:", response.data);

      resetForm();
      messageApi?.open({ type: "success", content: requestSuccessMessage });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const eventDetails = {
    name: eventData.EventTitle,
    description: eventData.EventSubtitle,
    location: eventData.EventVenue,
    startDate: eventData.EventDate,
    endDate: eventData.EventDate,
    startTime: formatTime(eventData.EventStartTime),
    endTime: formatTime(eventData.EventEndTime),
    timeZone: eventData.TimeZone || "UTC",
  };
  const styleLightCustom = '--btn-background:transaprent; --btn-border:none; --btn-shadow:none; --btn-active-shadow:none; --btn-hover-shadow:none; --btn-focus-shadow:none; --btn-hover-shadow:none; --btn-hover-background:transaparent; --btn-text: #fff; --btn-hover-text: #fff;';

  const buttonConfigs = [{
    color: '#fff',
    width: '100%',
    hoverFontColor: '#2251FF',
    backgroundColor: '#2251FF',
    borderColor: 'transparent',
    hoverBorderColor: '#2251FF',
    focusColor: '',
    hoverColor: 'transparent',
    clickColor: '',
    iconFillColor: 'none',
    hoverIconFillColor:'', 
    iconStrokeColor:'#fff',
    hoverIconStrokeColor: '',
  }
]

  return (
    <Fragment>
      <div className={"container"}>
        <div className={"event-hero-section"}>
          <div
            className={"hero-left-card"}
            style={{
              backgroundImage: `url(${
                eventData.CoverBanner && eventData.CoverBanner.data && eventData.CoverBanner.data.attributes
                  ? eventData.CoverBanner.data.attributes.url
                  : ""
              })`,
            }}
          >
            <div className={"card-content"}>
              <div className={"card-top-content"}>
                <div className={"breadcrumb-back"}>
                  <IoIosArrowRoundBack className={"breadcrumbback"} onClick={() => window.history.back()} />
                  {generateBreadcrumb()}
                </div>
                <h2 className={"event-main-title"}>{eventData.EventTitle || ""}</h2>
                <span className={"event-main-subtitle"}>{eventData.EventSubtitle || ""}</span>
              </div>
              <div className={"card-bottom-content"}>
                <span className={"eventdate"}>
                  {formatDate(`${eventData.EventDay || ""}, ${eventData.EventDate || ""}`)}
                </span>
                <div className={"time-event"}>
                  <span className={"EventTime"}>
                    {formatTime(`${eventData.EventStartTime || ""} - ${eventData.EventEndTime || ""}`)}
                  </span>
                  <span className={"event-card-venue"}>{eventData.EventVenue || ""}</span>
                </div>
                <div className={"event-card-action"}>
                  <AddToCalendar event={eventDetails} styleLight={styleLightCustom}/>
                  <ShareButton title={eventData.EventTitle}/>
                  {/* {eventData.EventAction &&
                    eventData.EventAction.map((action, index) => (
                      <a href={""} key={index || ""} onClick={() => handleEventAction(action)}>
                        <li>{action.Title || ""}</li>
                      </a>
                    ))
                    } */}
                </div>
              </div>
            </div>
          </div>
          <div className={"hero-right-card"}>
            <div className={"form-head"}>
              <div className={"top-head"}>
                <span className={"form-title"}>{eventData.FormTitle || ""}</span>
                <span className={"form-subtitle"}>
                  {eventData.FormSubtitle && eventData.FormSubtitle.length > 0
                    ? eventData.FormSubtitle[0].Subtitle || ""
                    : ""}
                </span>
              </div>
              <span className={"form-heading"}>
                {eventData.FormSubtitle && eventData.FormSubtitle.length > 1
                  ? eventData.FormSubtitle[1].Subtitle || ""
                  : ""}
              </span>
            </div>
            <div className={"form-layout"}>
              <Formik
                initialValues={eventData.FormInput.reduce((values, input) => {
                  values[input.Name] = "";
                  return values;
                }, {} as Record<string, string>)}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    {eventData.FormInput &&
                      eventData.FormInput.map((input) => (
                        <div key={input.id || ""} className={"event-form"}>
                          {input.InputType === "submit" ? (
                            // <button
                            //   type={"submit"}
                            //   className={"event-register"}
                            //   name={input.Name || ""}
                            //   value={input.Value || ""}
                            // >
                            //   {input.Title || ""}
                            // </button>
                            <XcelButton 
                                content={input?.Title}
                                type={"submit"}
                                buttonsCSS={buttonConfigs}
                            />
                          ) : (
                            <div>
                              <Field
                                type={input.InputType || ""}
                                className={"event-form-input"}
                                placeholder={input.Placeholder || ""}
                                name={input.Name || ""}
                                style={{ appearance: "none" }}
                              />
                              <ErrorMessage name={input.Name} component={"span"} className={"error-message"} />
                            </div>
                          )}
                        </div>
                      ))}
                  </Form>
                )}
              </Formik>
              <p className={"event-terms"}>{eventData.FormTerms || ""}</p>
            </div>
          </div>
        </div>
        <div className={"event-count-timer"}>
          <div className={"timer-count"}>
            <span className={"Timer-title"}>Starts In:</span>
            <span>{formatDays(timeRemaining || 0)} Days</span>
            <span>{formatHours(timeRemaining || 0)} Hrs</span>
            <span>{formatMinutes(timeRemaining || 0)} Mins</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventHero;
