import React from 'react';

interface AddressProps {
    title: string;
    address: string;
}

const Address: React.FC<AddressProps> = ({ title, address }) => {
    return (
        <div className={"footerMenuItem"}>
            <div className={"menuListSection"}>
                <h4 className={"menuListSection__title"}>{title}</h4>
                <ul className={"menuListSection__list"}>
                    <li>{address}</li>
                </ul>
            </div>
        </div>
    );
};

export default Address;
