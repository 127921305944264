import * as yup from "yup"

const emailErrorMessage = "Enter a valid email"
const string = yup.string().trim()
const stringRequired = string.required("This field is required")
const email = stringRequired
  .email(emailErrorMessage)
  .matches(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, emailErrorMessage)
  .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, emailErrorMessage)
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    emailErrorMessage
  )
const bool = yup.bool()
const boolRequired = bool.oneOf([true], "You must agree with privacy policy & terms")
export const initialStateFormChallenge = {
  name: "",
  email: "",
  challenge: "",
  isAgree: false,
}

export interface ValuesFormChallenge {
  name: string
  email: string
  challenge: string
  isAgree: boolean
}

export const formChallengeSchema = yup.object({
  name: stringRequired,
  email: email,
  challenge: stringRequired,
  isAgree: boolRequired,
})

export const initialStateFormSubscribeNow = {
  name: "",
  email: "",
  company: "",
  designation: "",
  phone: "",
  isAgree: false,
}

export interface ValuesFormSubscribeNow {
  name: string
  email: string
  company: string
  designation: string
  phone: string
  isAgree: boolean
}

export const formSubscribeNowSchema = yup.object({
  name: stringRequired,
  email: email,
  company: string,
  designation: string,
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must be numeric")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number can be at most 15 digits"), 
  isAgree: boolRequired,
})

export type WriteForUsRequestValues = {
  fullName: string
  email: string
  jobTitle: string
  articleSummary: string
  articleTitle: string
}
export const initialStateWriteForUsRequest = {
  fullName: "",
  email: "",
  jobTitle: "",
  articleSummary: "",
  articleTitle: "",
}
export const initialStateEmail = {
  email: "",
}

export interface ValuesEmail {
  email: string
}

export const emailSchema = yup.object({
  email: email,
})
export const initialStateFormContact = {
  name: "",
  email: "",
  message: "",
  receiveUpdates: false,
}

export interface ValuesFormContact {
  name: string
  email: string
  message: string
  receiveUpdates: boolean
}

export const formContactSchema = yup.object({
  name: stringRequired,
  email: email,
  message: stringRequired,
  receiveUpdates: bool,
})


export const initialStateEventForm: ValuesEventForm = {
  Name: '',
  CompanyName: '',
  WorkEmail: '',
  JobTitle: '',
  PhoneNumber: '',
};

export interface ValuesEventForm {
  Name: string;
  CompanyName: string;
  WorkEmail: string;
  JobTitle: string;
  PhoneNumber: string;
}

export const formEventSchema = yup.object({
  Name: stringRequired,
  CompanyName: stringRequired,
  WorkEmail: email,
  JobTitle: stringRequired,
  PhoneNumber: stringRequired.matches(/^[0-9]+$/, 'Phone number must be only digits')
    .min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number can be at most 15 digits')
});

