import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.less"

interface ButtonCSS {
  color?: string;
  hoverFontColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  hoverBorderColor?: string;
  focusColor?: string;
  hoverColor?: string;
  clickColor?: string;
  width?: string;
  iconFillColor: string; // Default fill color for the icon
  hoverIconFillColor: string; // Hover fill color for the icon
  iconStrokeColor: string; // Default stroke color for the icon
  hoverIconStrokeColor: string; // Hover stroke color for the icon
}

interface XcelButtonProps {
  content: string | React.ReactNode;
  buttonsCSS: ButtonCSS[];
  iconUrl?: string ; // URL for the SVG icon
  onClick?: () => void; // Optional for custom click handling
  type?: "submit" | "reset" | "button"; // Default to "button" if not provided
  cssIndex?: number;
  href?: string; // For redirection
  download?: string; // For file downloads
}

const XcelButton: React.FC<XcelButtonProps> = ({
  content,
  buttonsCSS,
  iconUrl,
  onClick,
  type = "button",
  cssIndex = 0,
  href,
  download,
}) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const css = buttonsCSS[cssIndex];

  useEffect(() => {
    if (iconUrl) {
      fetch(iconUrl)
        .then((response) => response.text())
        .then((svg) => setSvgContent(svg))
        .catch((error) => console.error("Failed to fetch SVG:", error));
    }
  }, [iconUrl]);

  const applyIconColor = (
    svg: string,
    fillColor: string,
    strokeColor: string
  ) => {
    return svg
      ? svg
          .replace(/fill="[^"]*"/g, `fill="${fillColor}"`)
          .replace(/stroke="[^"]*"/g, `stroke="${strokeColor}"`)
      : null;
  };

  const buttonStyle: React.CSSProperties = {
    fontSize: "16px",
    padding: "15px 36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    transition: "all 0.3s ease-in-out",
    borderRadius: "4px",
    height: "55px",
    fontFamily: `"Suisse Int'l Regular", sans-serif`,
    color: css?.color || "",
    backgroundColor: css?.backgroundColor || "",
    border: `1px solid ${css?.borderColor}` || "",
    width: "100%",
    cursor: "pointer",
    maxWidth: css?.width || 'max-content',
    minWidth: "200px",
  };

  const handleMouseOver = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.currentTarget.style.backgroundColor = css.hoverColor || "";
    e.currentTarget.style.color = css.hoverFontColor || "";
    e.currentTarget.style.borderColor = css.hoverBorderColor || "";
    setIsHovered(true);
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.currentTarget.style.backgroundColor = css.focusColor || "";
    e.currentTarget.style.color = css.hoverFontColor || "";
    e.currentTarget.style.borderColor = css.hoverBorderColor || "";
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.currentTarget.style.backgroundColor = css.clickColor || "";
    e.currentTarget.style.color = css.hoverFontColor || "";
    e.currentTarget.style.borderColor = css.hoverBorderColor || "";
  };

  const handleMouseOut = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.currentTarget.style.backgroundColor = css.backgroundColor || "";
    e.currentTarget.style.color = css.color || "";
    e.currentTarget.style.borderColor = css.borderColor || "";
    setIsHovered(false);
  };

  const inlineSVG = svgContent
    ? applyIconColor(
        svgContent,
        isHovered ? css.hoverIconFillColor : css.iconFillColor,
        isHovered ? css.hoverIconStrokeColor : css.iconStrokeColor
      )
    : null;

  if (href) {
    return (
      <Link
        to={download ? download : href}
        className={"xcelLink"}
        // download={download}
        style={buttonStyle}
        onMouseOver={handleMouseOver}
        onFocus={handleFocus}
        onMouseDown={handleMouseDown}
        onMouseOut={handleMouseOut}
      >
        {content}
        {inlineSVG && (
          <span
            dangerouslySetInnerHTML={{ __html: inlineSVG }}
            style={{  transition: "all 0.3s ease", }}
          />
        )}
      </Link>
    );
  }

  return (
    <button
    className={"xcelButton"}
      type={type}
      style={buttonStyle}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onFocus={handleFocus}
      onMouseDown={handleMouseDown}
      onMouseOut={handleMouseOut}
    >
      {content}
      {inlineSVG && (
        <span
          dangerouslySetInnerHTML={{ __html: inlineSVG }}
          style={{  transition: "all 0.3s ease", }}
        />
      )}
    </button>
  );
};

export default XcelButton;
