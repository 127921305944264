import React from 'react';
import { Link } from 'react-router-dom';

interface SecondaryLink {
    Title: string;
    Redirect: string | null;
    isActive: boolean;
    Target?: string;
}

interface SecondaryMenuProps {
    secondaryMenu: SecondaryLink[];
}

const SecondaryMenu: React.FC<SecondaryMenuProps> = ({ secondaryMenu }) => {
    return (
        <div className={"footerOtherMenuList"}>
            <ul>
                {secondaryMenu.map(
                    (link) =>
                        link.isActive && (
                            <li key={link.Title}>
                                {link.Redirect ? (
                                    <Link to={link.Redirect} target={link.Target || '_self'}>
                                        {link.Title}
                                    </Link>
                                ) : (
                                    <span>{link.Title}</span>
                                )}
                            </li>
                        )
                )}
            </ul>
        </div>
    );
};

export default SecondaryMenu;
