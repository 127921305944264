import {FC} from 'react';
import './style.less'
interface ShareButtonProps {
  title: string;
  url?: string;
}

const ShareButton: FC<ShareButtonProps> = ({ title, url = window.location.href }) => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title,
        url,
      })
      .then(() => console.log('Thanks for sharing!'))
      .catch(error => console.error('Error sharing:', error));
    } else {
      console.error('Web Share API is not supported in this browser.');
    }
  };

  return (
    <button onClick={handleShare} className={'shareBtn'}>
      Share
    </button>
  );
};

export default ShareButton;
