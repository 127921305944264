import React from "react";
import { XPContainer } from "../HeaderStyle";
import { Link } from "react-router-dom";
import DropCard from "./DropCard";
import { DropListProps } from "../GlobalTypes";
import { SvgIcon } from "src/components/ui/SvgIcon";

const DropList: React.FC<DropListProps> = ({ isOpen, items, card, dropDownTitle, secondaryListItems }) => {
    const dropTitles = items.filter(link => link?.attributes.Title);
    const hasMultipleCards = card && card?.data?.length > 1;

    const renderDropList = (links: any) => (
        links.filter((link: any) => link.attributes.isActive).map((link: any) => (
            <div key={link.id} className={"drop-main-list"}>
                {link?.attributes?.Redirect ? (
                    <Link
                        className={`drop-title ${location.pathname === link.attributes.Redirect ? "active" : ""}`}
                        to={link.attributes.Redirect}
                    >
                        {link.attributes.Title}
                    </Link>
                ) : (
                    <span className={"drop-title"}>{link.attributes.Title}</span>
                )}
                <ul className={"drop-list"}>
                    {link.attributes.dropLinks.filter((dropLink: any) => dropLink.isActive).map((dropLink: any) => (
                        <li
                            key={dropLink.id}
                            className={`drop-list-items ${dropLink.Redirect && location.pathname === dropLink.Redirect ? "active" : ""}`}
                        >
                            <Link to={dropLink?.Redirect || ""}>
                            {dropLink.Title}
                                {/* {dropLink.Icon?.data?.attributes?.url && <img src={dropLink.Icon.data?.attributes.url} alt={""} />} */}
                                {/* <span className={"drop-list-item-span"}></span> */}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        ))
    );
    
    

    return (
        <div className={`dropDown-container-section ${isOpen ? "xpDropOverlay" : ""}`}>
            <XPContainer className={"container"}>
                <SvgIcon type={"GreyClip"} className={"dropDownBg"} />
                {hasMultipleCards ? (
                    <div className={"dropDown-container insightContainer"}>
                        <div className={"dropListTitle"}>
                            <span className={"titleText"}>{dropDownTitle}</span>
                        </div>
                        <div className={"feturedInsight"}>
                            <span className={"drop-title"}>FEATURED INSIGHTS</span>
                            <div className={"drop-card-container insightCardContainer"}>
                                <DropCard drop_cards={card} />
                            </div>
                        </div>
                        {secondaryListItems && secondaryListItems.length > 0 && (
                            <div className={"second-drop-list-container"}>
                                {renderDropList(secondaryListItems)}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={"drop-main-container"}>
                        <div className={"dropDown-container"}>
                            <div className={"dropListTitle"}>
                                <span className={"titleText"}>{dropDownTitle}</span>
                            </div>
                            <div className={"drop-list-container"}>
                                {renderDropList(items)}
                            </div>
                            {card && card?.data?.length > 0 ? (
                                <div className={"drop-card-container"}>
                                    <DropCard drop_cards={card} />
                                </div>
                            ) : (
                                secondaryListItems && secondaryListItems.length > 0 && (
                                    <div className={"second-drop-list-container"}>
                                        {renderDropList(secondaryListItems)}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}
            </XPContainer>
        </div>
    );
    
};

export default DropList;
