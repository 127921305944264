import React, { useEffect, useState } from "react";
import { XPContainer } from "./HeaderStyle";
import { Link } from "react-router-dom";
import { TopHeaderProps, TopLink } from "./GlobalTypes";

const TopHeader: React.FC<TopHeaderProps> = ({ topheader }) => {
    const [topHeaderList, setTopHeaderList] = useState<TopLink[]>([]);

    useEffect(() => {
        if (topheader?.isActive === true) {
            setTopHeaderList(topheader.TopLinks);
        }
    }, [topheader]);

    const isActiveTopMenu = (link: TopLink) => {
        // Ensure link.Redirect is a valid string and check for exact or broader matches
        if (link.Redirect && (location.pathname === link.Redirect || location.pathname.startsWith(`${link.Redirect}/`))) {
            return true;
        }
    
        return false;
    };
    

    return (
        <section className={"top-header-section"}>
            <XPContainer className={"container"}>
                <div className={"topContainer"}>
                    <ul className={"top-nav-list"}>
                        {topHeaderList.filter(link => link.isActive).map(link => (
                            <li key={link.id} className={`top-list-item ${isActiveTopMenu(link) ? "active" : ""}`}>
                                <Link to={link.Redirect} className={"item-redirect"}>
                                    <span className={"item-name"}>
                                        {link.Title}
                                    </span>
                                    {link?.Icon?.data?.attributes?.url && (
                                        <img
                                            className={"item-icon"}
                                            src={link?.Icon?.data?.attributes?.url}
                                            alt={link.Title}
                                        />
                                    )}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </XPContainer>
        </section>
    );
    
};

export default TopHeader;
