import { FC } from "react"
import { NavLink } from "react-router-dom"
import { SvgIcon } from "../SvgIcon"

const Link: FC<{
  link?:
    | Maybe<LinkFragment>
    | Maybe<{
        url: Maybe<string>
        title: Maybe<string>
        isMeeting?: boolean
        file?: Maybe<{ data?: Maybe<{ attributes?: Maybe<{ url: string }> }> }>
      }>
  url?: string
  tag?: string
  target?:string
  className?: string
  arrowIcon?: boolean
  onClick?: () => void
  calendar?: boolean
}> = ({ link, className, arrowIcon, url, tag, onClick, calendar, target }) => {
  if (link?.file?.data?.attributes?.url) {
    return (
      <NavLink className={`${className} downloadLink`} to={link?.file?.data?.attributes?.url ?? url} target={"_blank"} onClick={onClick} rel={"noreferrer"}>
        {link?.title ?? tag}
        <SvgIcon type={"Download"} className={"downloadIcon"} />
      </NavLink>
    )
  }
  return (
    link?.url &&
    link?.title && (
      <NavLink className={`${className} ${link?.isMeeting && "calendarLink"}`} to={link?.url.trim()} onClick={onClick}>
        {link?.title}
        {(calendar || link?.isMeeting) && <SvgIcon type={"Calendar"} className={"calendarIcon"} />}
        {arrowIcon && !link?.isMeeting && <span className={"arrowIcon"} />}
      </NavLink>
    )
  )
}

export { Link }
