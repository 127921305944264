import React from 'react';
import Address from './Address';
import SecondaryMenu from './FooterSecondaryMenu';
import FooterMenuCard from './FooterMenuCard';
import SocialMedia from './SocialIconComponent';

interface MenuItem {
    id: string;
    attributes: {
        title: string;
        isActive: boolean;
        redirect?: string | null;
        target?: string | null;
        footerMenuListItem: {
            id: string;
            title: string;
            isActive: boolean;
            redirect?: string | null;
            target?: string | null;
            badgeTitle?: string | null;
            badgeActive?: boolean | null;
        }[];
    };
}

interface Address {
    title: string;
    address: string;
    isActive: boolean;
}

interface SecondaryLink {
    Title: string;
    Redirect: string | null;
    isActive: boolean;
    Target?: string;
    secondaryLink?: any;
}

interface SocialIcon {
    title: string;
    redirect: string;
    icon: {
        data: {
            attributes: {
                url: string;
            };
        };
    };
}

interface FooterMenuProps {
    footermenuIsActive: boolean;
    footerMenu: MenuItem[];
    address?: Address;
    secondaryMenuIsActive?: boolean;
    secondaryMenu?: SecondaryLink[];
    socialMediaTitle: string;
    socialMediaIsActive: boolean;
    socialMedia?: SocialIcon[];
}

const FooterMenu: React.FC<FooterMenuProps> = ({
    footerMenu,
    footermenuIsActive,
    address,
    secondaryMenu,
    secondaryMenuIsActive,
    socialMedia,
    socialMediaTitle,
    socialMediaIsActive
}) => {
    return (
        <React.Fragment>
            {footermenuIsActive && (
                <div className={"container"}>
                    <div className={"footerMenu"}>
                        {(footermenuIsActive || address?.isActive) && (
                            <React.Fragment>
                                <div className={"footerMenuList"}>
                                    {footerMenu.map((menuItem) => (
                                        <FooterMenuCard key={menuItem.id} menuItem={menuItem} />
                                    ))}

                                    {address?.isActive && (
                                        <Address title={address.title} address={address.address} />
                                    )}
                                </div>
                                {(socialMedia && socialMediaIsActive) && (
                                    <SocialMedia socialMedia={socialMedia} socialMediaTitle={socialMediaTitle} isActive={socialMediaIsActive} />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
            {secondaryMenuIsActive && (
                <div className={"footerSecondaryMenu"}>
                    <div className={"container"}>
                        {secondaryMenu && secondaryMenu.map((menu, index) => (
                            <SecondaryMenu key={index} secondaryMenu={menu.secondaryLink} />
                        ))}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default FooterMenu;
