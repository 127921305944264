import { message } from "antd"
import { MessageInstance } from "antd/lib/message/interface"
import {
  Dispatch,
  FC,
  Fragment,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"

type ContextProps = {
  openKeys: string[]
  setOpenKeys: Dispatch<SetStateAction<string[]>>
  refLoading: MutableRefObject<null> | { current: Element }
  setRefLoading: Dispatch<SetStateAction<MutableRefObject<null> | { current: Element }>>
  Pageloading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  trendingBlogPost: Maybe<Maybe<BlogPostCardFragment & { isOpen: boolean }>[]>
  setTrendingBlogPost: Dispatch<SetStateAction<Maybe<Maybe<BlogPostCardFragment & { isOpen: boolean }>[]>>>
  openPopUp: boolean
  setOpenPopUp: Dispatch<SetStateAction<boolean>>
  messageApi: Maybe<MessageInstance>
  contextHolder: ReactElement
  fileURL: string
  setFileURL: Dispatch<SetStateAction<string>>
}

export const defaultValues: ContextProps = {
  fileURL: "",
  setFileURL: () => null,
  openKeys: [],
  setOpenKeys: () => null,
  refLoading: { current: document.getElementById("root") },
  setRefLoading: () => null,
  Pageloading: false,
  setLoading: () => false,
  trendingBlogPost: [],
  setTrendingBlogPost: () => null,
  openPopUp: false,
  setOpenPopUp: () => false,
  messageApi: null,
  contextHolder: <Fragment />,
}

const Context = createContext(defaultValues)
const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openKeys, setOpenKeys] = useState(defaultValues.openKeys)
  const [refLoading, setRefLoading] = useState(defaultValues.refLoading)
  const [Pageloading, setLoading] = useState(defaultValues.Pageloading)
  const [trendingBlogPost, setTrendingBlogPost] = useState(defaultValues.trendingBlogPost)
  const [openPopUp, setOpenPopUp] = useState(defaultValues.openPopUp)
  const [messageApi, contextHolder] = message.useMessage()
  const [fileURL, setFileURL] = useState(defaultValues.fileURL)

  return (
    <Context.Provider
      value={{
        fileURL,
        setFileURL,
        openKeys,
        setOpenKeys,
        refLoading,
        setRefLoading,
        Pageloading,
        setLoading,
        trendingBlogPost,
        setTrendingBlogPost,
        openPopUp,
        setOpenPopUp,
        messageApi,
        contextHolder,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useGeneralContext = (): ContextProps => useContext(Context)
export { ContextProvider, useGeneralContext }
