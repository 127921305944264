import React, { FC } from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import './style.less';

type CalendarEvent = {
    name: string;
    description?: string;
    location?: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    timeZone: string;
};

type AddToCalendarButtonProps = {
    event: CalendarEvent;
    styleLight?: string;
};

const AddToCalendar: FC<AddToCalendarButtonProps> = ({ event, styleLight }) => {
    return (
        <AddToCalendarButton
            name={event.name}
            options={['Apple', 'Google', 'Outlook.com', 'MicrosoftTeams', 'Microsoft365']}
            location={event.location || "World Wide Web"}
            startDate={event.startDate}
            endDate={event.endDate}
            startTime={event.startTime}
            endTime={event.endTime}
            trigger={"hover"}
            inline={"true"}
            bypassWebViewCheck
            listStyle={"dropdown"}
            timeZone={event.timeZone || "America/Los_Angeles"}
            hideBackground={"True"}
            styleLight={styleLight || '--btn-background:transparent; --btn-border:none; --btn-shadow:none; --btn-active-shadow:none; --btn-hover-shadow:none; --btn-hover-background:transparent;'}
            forceOverlay={"true"}
            hideBranding={"true"}
        />
    );
};

export default AddToCalendar;
