import { LegacyRef, MutableRefObject, RefObject, useEffect, useMemo, useRef, useState } from "react"
import { useGeneralContext } from "../context"

export function useIsInViewport(ref: MutableRefObject<null> | { current: Element }): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), [])

  useEffect(() => {
    ref.current !== null && observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export const useOutsideClick = (callback: () => void): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    document.addEventListener("click", handleClick, true)

    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [callback, ref])

  return ref
}

export const useZohoChat = (): { ready: boolean; click: () => void } => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    window.addEventListener("zohoReady", () => setReady(true))
    return () => {
      window.removeEventListener("zohoReady", () => setReady(false))
    }
  }, [])
  return {
    ready,
    click: () => {
      const button = document.getElementById("zsiq_float")
      button?.click()
    },
  }
}

export const useRefAndIsVisible = (
  loadingArg?: boolean,
  refLoadingArg?: MutableRefObject<null> | { current: Element },
  isNotPage?: boolean
): [LegacyRef<HTMLDivElement> | undefined, boolean] => {
  const { refLoading: refLoadingSinglePage, Pageloading: loadingSinglePage } = useGeneralContext()
  const loading = loadingArg ?? loadingSinglePage
  const refLoading = refLoadingArg ?? refLoadingSinglePage
  const ref = useRef(null)
  const isVisible = useIsInViewport(loading || isNotPage ? refLoading : ref);
  // console.log("Ref assigned to:", ref.current);
  // console.log("Is section visible:", isVisible);
  return [ref, isVisible]
}

export const useCarousel = (): [null, null, MutableRefObject<null>, MutableRefObject<null>] => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const slider1 = useRef(null)
  const slider2 = useRef(null)

  useEffect(() => {
    setNav1(slider1?.current ? slider1.current : null)
    setNav2(slider2?.current ? slider2.current : null)
  }, [])
  return [nav1, nav2, slider1, slider2]
}

export const useMenuTop = (): void => {
  const { openKeys } = useGeneralContext()
  const [antMenu] = document.getElementsByClassName("ant-menu-sub")
  useEffect(() => {
    if (antMenu) {
      antMenu.scrollTo({ top: 0 })
    }
  }, [antMenu, openKeys])
}

export function useDebounce(value: string, delay = 500): string {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const useGatedContent = (gated: Maybe<boolean>, popUp: () => void): void => {
  const actions = ["copy", "cut", "contextmenu"]

  const preventActions = (e: Event) => {
    let targetElement = e.target as HTMLElement

    while (targetElement) {
      if (targetElement.tagName === "A") {
        return // Allow context menu for links
      }
      targetElement = targetElement.parentElement as HTMLElement
    }

    // If no link found in parents, prevent default and show popup
    popUp()
    e.preventDefault()
  }

  useEffect(() => {
    if (gated) {
      actions?.forEach(action => {
        action && window.addEventListener(action, preventActions)
      })

      return () => {
        actions.forEach(action => {
          action && window.removeEventListener(action, preventActions)
        })
      }
    }

    return () => {
      // No specific cleanup logic here
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, gated])
}
