import React, {Fragment, FC} from 'react';
import SocialMedia from './SocialIconComponent';


interface FooterCopyRightProps {
    copyRightTitle: string;
    copyRightIsActive: boolean
}

const FooterCopyRight: FC<FooterCopyRightProps> = ({ copyRightTitle, copyRightIsActive}) => {
    return (
        <div className={"container"}>
            {
                copyRightIsActive && (

                    <div className={"footerCopyRight"}>
                        { copyRightIsActive && (
                            <React.Fragment>
                            <div className={"copyRight"}>
                                {copyRightTitle}
                            </div>
                            <div className={"contact-numb"}>
                                <a href={"tel: 630-869-0901"} className={"phoneNumb"}>630-869-0901</a>
                            </div>
                            <div className={"contact-email"}>
                                <a href={"mailto:contact@xcelpros.com"} className={"mailId"}>contact@xcelpros.com</a>
                            </div>
                            </React.Fragment>
                        )}

                        
                    </div>
                )
            }
        </div>
    );
};

export default FooterCopyRight;
