import React from 'react';
import { Link } from 'react-router-dom';

interface MenuItem {
    id: string;
    attributes: {
        title: string;
        isActive: boolean;
        redirect?: string | null;
        target?: string | null;
        footerMenuListItem: {
            id: string;
            title: string;
            redirect?: string | null;
            target?: string | null;
            isActive?: boolean | null;
            badgeTitle?: string | null;
            badgeActive?: boolean | null;
        }[];
    };
}

const FooterMenuCard: React.FC<{ menuItem: MenuItem }> = ({ menuItem }) => {
    return (
        <React.Fragment>
            {
                menuItem.attributes.isActive && (
                    <div className={"footerMenuItem"} key={menuItem.id}>
            <div className={"menuListSection"}>
                {menuItem.attributes.redirect ? (
                    <h4 className={"menuListSection__title"}>
                        <Link to={menuItem.attributes.redirect} target={menuItem.attributes.target || '_self'}>
                            {menuItem.attributes.title}
                        </Link>
                    </h4>
                ) : (
                    <h4 className={"menuListSection__title"}>{menuItem.attributes.title}</h4>
                )}
                <ul className={"menuListSection__list"}>
                    {menuItem.attributes.footerMenuListItem.map((listItem) => (
                        listItem.isActive && ( 
                            <li key={listItem.id}>
                                {listItem.redirect ? (
                                    <Link to={listItem.redirect} target={listItem.target || '_self'}>
                                        {listItem.title}
                                    </Link>
                                ) : (
                                    listItem.title
                                )}
                                {listItem.badgeActive && listItem.badgeTitle && ( 
                                    <span className={"badge"}>{listItem.badgeTitle}</span>
                                )}
                            </li>
                        )
                    ))}
                </ul>
            </div>
        </div>
                )
            }
        </React.Fragment>
    );
};

export default FooterMenuCard;
