import { FC, SVGProps } from "react"

import { ReactComponent as ArrowLink } from "../../../icons/arrowLink.svg"
import { ReactComponent as Calendar } from "../../../icons/calendar.svg"
import { ReactComponent as Chat } from "../../../icons/chat.svg"
import { ReactComponent as Check } from "../../../icons/check.svg"
import { ReactComponent as Clock } from "../../../icons/clock.svg"
import { ReactComponent as Download } from "../../../icons/download.svg"
import { ReactComponent as Home } from "../../../icons/home.svg"
import { ReactComponent as Letter } from "../../../icons/letter.svg"
import { ReactComponent as Logo } from "../../../icons/logo.svg"
import { ReactComponent as Loup } from "../../../icons/loup.svg"
import { ReactComponent as MapPin } from "../../../icons/map-pin.svg"
import { ReactComponent as Minus } from "../../../icons/minus.svg"
import { ReactComponent as Phone } from "../../../icons/phone.svg"
import { ReactComponent as Plus } from "../../../icons/plus.svg"
import { ReactComponent as Search } from "../../../icons/search.svg"
import { ReactComponent as Mailbox } from "../../../icons/mailBox-icon.svg"
import { ReactComponent as BlackClip } from "../../../icons/clip-pin-black.svg"
import { ReactComponent as GreyClip } from "../../../icons/clip-pin-grey.svg"
import { ReactComponent as Chevron } from "../../../icons/chevron.svg"

export type IconTypes =
  | "ArrowLink"
  | "Calendar"
  | "Chat"
  | "Check"
  | "Download"
  | "Loup"
  | "Logo"
  | "Plus"
  | "Search"
  | "Home"
  | "Letter"
  | "Phone"
  | "Minus"
  | "CLock"
  | "MapPin"
  | "Chevron"
  | "Mailbox"
  | "BlackClip"
  | "GreyClip"
  | string

const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  ArrowLink: ArrowLink,
  Calendar: Calendar,
  Chat: Chat,
  Check: Check,
  Download: Download,
  Loup: Loup,
  Logo: Logo,
  Plus: Plus,
  Search: Search,
  Home: Home,
  Letter: Letter,
  Phone: Phone,
  Minus: Minus,
  Clock: Clock,
  MapPin: MapPin,
  Chevron: Chevron,
  Mailbox: Mailbox,
  BlackClip: BlackClip,
  GreyClip: GreyClip,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes }

const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}

export { SvgIcon }
